<!--
 * @Description: 用户页面列表详情页面
 * @Author: zhang zhen
 * @Date: 2023-02-14 20:51:14
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-03 10:36:57
 * @FilePath: /page-sass/src/views/requirement/requirementList/modules/ForUserAndPurchaseDetails.vue
-->
<template>
  <div class="ForUserAndPurchaseDetails info-container-box">
    <a-row type="flex" justify="space-between" align="middle">
      <a-col>
        <div class="ForUserAndPurchaseDetails-title">{{ purchaseTitle }}</div>
        <div class="ForUserAndPurchaseDetails-subtitle">
          <span class="label">需求编号：{{ purchaseNo }}</span>
          <a class="copy" @click="handleCopy">复制</a>
        </div>
      </a-col>
      <a-col>
        <!-- <a-input-search v-if="activeTab == 'RecommendedInfo'" placeholder="请输入您要邀请的生产商" style="width: 240px;height: 38px;" @search="onSearch" /> -->
        <!-- <a-button type="primary" @click="handleCloseRequirement">关闭需求</a-button> -->
      </a-col>
    </a-row>
    <a-tabs v-model="activeTab" @change="handleChangeTab">
      <a-tab-pane key="BasicInfo" tab="概览">
        <BasicInfo ref="BasicInfo" :purchaseId="purchaseId" :formType="formType" :infoStatus="status" :needOption="true" @setInfo="handleSetInfo" />
      </a-tab-pane>
      <a-tab-pane key="RecommendedInfo" tab="邀请">
        <RecommendedInfo ref="RecommendedInfo" :purchaseId="purchaseId" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import BasicInfo from './basicInfo.vue'
import InterestedCard from '@/components/tools/interestedCard.vue'
import RecommendedInfo from './recommendedInfo.vue'
import ParticipatePart from './ParticipatePart.vue'
import Cooperation from './cooperation.vue'
import { getAction } from '@/api/manage'
export default {
  name: 'ForUserAndPurchaseDetails',
  components: {
    BasicInfo,
    InterestedCard,
    RecommendedInfo,
    ParticipatePart,
    Cooperation,

  },
  data() {
    return {
      activeTab: 'BasicInfo',
      purchaseTitle: '',
      purchaseNo: '',
      purchaseId: '',
      formType: 'product',
      status: '1',
      loading: false,
      newFlag: false,
    }
  },
  created() {
    const { purchaseId, purchaseTitle, purchaseNo, activeTab, formType, status } = this.$route.query
    purchaseId && (this.purchaseId = purchaseId)
    purchaseTitle && (this.purchaseTitle = purchaseTitle)
    purchaseNo && (this.purchaseNo = purchaseNo)
    activeTab && this.handleChangeTab(activeTab)
    formType && (this.formType = formType)
    this.status = status || 0
  },
  methods: {
    /* 复制单号 */
    handleCopy() {
      const _input = document.createElement('input') // 直接构建input
      _input.value = this.purchaseNo // 设置内容
      document.body.appendChild(_input) // 添加临时实例
      _input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(_input) // 删除临时实例
      this.$message.success('复制成功')
    },
    handleCloseRequirement() {
      getAction(`/purchases/close/${this.purchaseId}`).then(res => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          this.$router.go(-1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleChangeTab(key) {
      this.activeTab = key
      this.$nextTick(_ => {
        if (key == 'BasicInfo') {
          this.$refs.BasicInfo.handleLoadData()
        } else if (key == 'RecommendedInfo') {
          this.$refs.RecommendedInfo.handleRefresh()
        } else if (key == 'ParticipatePart') {
          this.$refs.ParticipatePart.handleSetSearch(null)
          // this.newFlag = false
        } else if (key == 'Cooperation') {
          this.$refs.Cooperation.handleLoadInfo()
        }
      })
    },
    onSearch(e) {
      this.$refs.RecommendedInfo.handleSetSearch(e)
    },
    handleSetInfo(e) {
      this.newFlag = e
    }
  }
}
</script>

<style lang="less" scoped>
.ForUserAndPurchaseDetails {
  ::v-deep.ant-tabs {
    color: rgba(0, 0, 0, 0.85);
  }
  &-title {
    color: #090b12;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  &-subtitle {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 15px;
    .copy {
      font-size: 13px;
      margin-left: 8px;
      font-weight: 500;
    }
  }
  ::v-deep .ant-badge-dot {
    top: -2px;
    right: -2px;
  }
}
::v-deep .ant-tabs-tab-active {
  font-weight: 500;
}

.btns {
  text-align: center;
}
@import '~@/styles/detail.less';
</style>
